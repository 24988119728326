import { SvgIconProps } from '@material-ui/core';
import { FC, forwardRef } from 'react';
import { ReactComponent as ArrowRightIconSvg } from './arrow-right.svg';
import { ReactComponent as CarouselArrowIconSvg } from './carousel-arrow.svg';
import { ReactComponent as FbIconSvg } from './fb-icon.svg';
import { ReactComponent as FbDarkIconSvg } from './fb-icon-dark.svg';
import { ReactComponent as LnIconSvg } from './ln-icon.svg';
import { ReactComponent as LnIconDarkSvg } from './ln-icon-dark.svg';
import { ReactComponent as InstagramIconSvg } from './instagram-icon.svg';
import { ReactComponent as InstagramIconDarkSvg } from './instagram-icon-dark.svg';
import { ReactComponent as EmailIconSvg } from './email-icon.svg';
import { ReactComponent as PhoneIconSvg } from './phone-icon.svg';
import { ReactComponent as ConstructionIconSvg } from './construction-icon.svg';
import { ReactComponent as BigArrowRightIconSvg } from './big-arrow-right.svg';
import { ReactComponent as LogoU1Svg } from './logo-u1.svg';
import { ReactComponent as CloseIconSvg } from './close-icon.svg';
import { ReactComponent as ConstructionOrangeIconSvg } from './constructionOrangeIconSvg.svg';
import { ReactComponent as PlusIconSvg } from './plus-icon.svg';
import { ReactComponent as MinusIconSvg } from './minus-icon.svg';
import { ReactComponent as CalendarIconSvg } from './calendarIconSvg.svg';
import { ReactComponent as CheckboxIconSvg } from './checkbox-icon.svg';
import { ReactComponent as CheckboxCheckedIconSvg } from './checkbox-checked-icon.svg';
import { ReactComponent as OrangeArrowIconPosSvg } from './orange-arrow-icon.svg';
import { ReactComponent as TwitterIconSvg } from './twitter.svg';
import { ReactComponent as ShareIconSvg } from './share-icon.svg';
import { ReactComponent as ArrowIconSvg } from './ArrowIconSvg.svg';
import { ReactComponent as ArrowLeftGreyIconSvg } from './arrow-left-grey.svg';

export const ArrowRightIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowRightIconSvg ref={ref} {...props} />);
export const FbIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FbIconSvg ref={ref} {...props} />);
export const FbDarkIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FbDarkIconSvg ref={ref} {...props} />);
export const LnIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LnIconSvg ref={ref} {...props} />);
export const LnDarkIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LnIconDarkSvg ref={ref} {...props} />);
export const InstagramIcon: FC<SvgIconProps> = forwardRef((props, ref) => <InstagramIconSvg ref={ref} {...props} />);
export const InstagramDarkIcon: FC<SvgIconProps> = forwardRef((props, ref) => <InstagramIconDarkSvg ref={ref} {...props} />);
export const EmailIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EmailIconSvg ref={ref} {...props} />);
export const PhoneIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PhoneIconSvg ref={ref} {...props} />);
export const ConstructionIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ConstructionIconSvg ref={ref} {...props} />);
export const BigArrowRightIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BigArrowRightIconSvg ref={ref} {...props} />);
export const LogoU1: FC<SvgIconProps> = forwardRef((props, ref) => <LogoU1Svg ref={ref} {...props} />);
export const CloseIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CloseIconSvg ref={ref} {...props} />);
export const ConstructionOrangeIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ConstructionOrangeIconSvg ref={ref} {...props} />
));
export const PlusIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PlusIconSvg ref={ref} {...props} />);
export const MinusIcon: FC<SvgIconProps> = forwardRef((props, ref) => <MinusIconSvg ref={ref} {...props} />);
export const CheckboxIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CheckboxIconSvg ref={ref} {...props} />);
export const CheckboxCheckedIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CheckboxCheckedIconSvg ref={ref} {...props} />);
export const TwitterIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TwitterIconSvg ref={ref} {...props} />);
export const ShareIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ShareIconSvg ref={ref} {...props} />);

interface Position {
  position: 'right' | 'left' | 'up' | 'down';
}

export enum PositionValues {
  left = 0,
  up = 90,
  right = 180,
  down = 270,
}

interface SvgIconPositionProps extends SvgIconProps, Position {}

export const CarouselArrowIconPos: FC<SvgIconPositionProps> = forwardRef((props, ref) => (
  <CarouselArrowIconSvg style={{ transform: `rotate(${PositionValues[props.position]}deg)` }} ref={ref} {...props} />
));
export const CalendarIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CalendarIconSvg ref={ref} {...props} />);

export const OrangeArrowIconPos: FC<SvgIconPositionProps> = forwardRef((props, ref) => (
  <OrangeArrowIconPosSvg style={{ transform: `rotate(${PositionValues[props.position]}deg)` }} ref={ref} {...props} />
));

export const ArrowIcon: FC<SvgIconPositionProps> = forwardRef((props, ref) => (
  <ArrowIconSvg style={{ transform: `rotate(${PositionValues[props.position]}deg)` }} ref={ref} {...props} />
));

export const ArrowLeftGreyIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowLeftGreyIconSvg ref={ref} {...props} />);
